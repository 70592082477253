.wrapperInput {
    position: relative;
    width: 400px;
    height: 40px;
    padding: 0 8px;
    border-radius: 9px;
    border: 1px solid var(--border-color);
    background: var(--main-background-color);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.error {
        border-color: var(--red-main-color);

        input {
            color: var(--red-main-color);
        }
    }

    .errorDetail {
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: var(--red-main-color);
    }

    svg {
        flex-shrink: 0;
        margin-left: 4px;
        margin-right: 8px;
    }

    input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: 0;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    button {
        height: 24px;
        padding: 0 8px;
        border-radius: 3px;
        background: var(--gray-light-color);
        border: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
    }
}

.wrapperSelectCheck {
    padding-top: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .title {
        padding-bottom: 10px;
    }
    .item {
        display: flex;
        align-items: center;
        label {
            margin-left: 5px;
            font-size: 10px;
        }
    }
}
