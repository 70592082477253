.btn {
    border: 0;
    padding: 0 12px;
    height: 40px;
    border-radius: 9px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &.regular {
        background: var(--blue-main-color);
        color: #F9FAFB;
    }

    &.full {
        width: 100%;
    }

    &.redRegular {
        background: var(--red-main-color);
        color: var(--main-background-color);
    }

    &.border {
        background: var(--main-background-color);
        border: 1px solid var(--border-color);
    }

    &:disabled {
        opacity: 0.5;
    }
}
