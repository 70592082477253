@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');

:root {
  --header-color: #323949;
  --blue-main-color: #3B82F6;
  --red-main-color: #EF4444;
  --border-color: #E5E7EB;
  --regular-text-color: #1F2937;
  --gray-text-color: #6B7280;
  --gray-light-color: #F3F4F6;
  --main-background-color: #fff;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: var(--regular-text-color);
  background-color: var(--main-background-color);
}


* {
  outline: none;
}
