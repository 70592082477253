.wrapperDeletePaymentScreen {
    height: 100%;

    .wrapperProgressSpinner {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f3f4f6c1;
        z-index: 999999;
    }

    .firstScreen {
        padding-top: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        h1 {
            padding: 0;
            margin: 0;
            padding-top: 24px;
            padding-bottom: 16px;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.72px;
        }

        p {
            color: var(--gray-text-color);
            padding: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        form {
            max-width: 400px;
            width: 100%;

            button[type="submit"] {
                margin-top: 32px;
            }
        }
    }

    .mainScreen {
        .wrapperActions {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            form {
                display: flex;
                align-items: flex-start;
                gap: 8px;

                margin-right: auto;
            }
        }
    }

    .wrapperTables {
        padding-top: 32px;
        display: flex;
        gap: 12px;

        .wrapperProgressSpinner {
            position: absolute;
            background: var(--main-background-color);
        }


        .wrapperTable {
            position: relative;
            width: 100%;
        }
        .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.48px;
            margin-bottom: 12px;
        }
    }

    .table {
        width: 100%;
        border: 1px solid #F3F4F6;
        border-radius: 12px;
        overflow: hidden;

        .tableHeader, .tableRow, .tableFooter {
            display: flex;
            width: 100%;
        }

        .tableRow {
            cursor: pointer;
            &:hover, &.active {
                background: #F3F4F6;
            }
        }

        .tableCol {
            flex: 1;
            padding: 12px;
            border-right: 1px solid #F3F4F6;
            &:last-child {
                border: 0;
            }
        }

        .tableHeader {
            background: var(--border-color);
        }

        .tableRow {
            border-bottom: 1px solid #F3F4F6;
        }

        .tableFooter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            .text {
                justify-content: space-between;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
            }
        }
    }

    .headerScreen {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            color: var(--gray-text-color);
            padding: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.42px;
        }

        .userName {
            display: flex;
            align-items: center;
            gap: 4px;
            .avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: var(--border-color);
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 11px;
                    path {
                        stroke: #000;
                    }
                }
            }
        }
    }
}
