.wrapperPagination {
    display: flex;
    align-items: center;
    gap: 4px;
    button {
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9CA3AF;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        border-radius: 4px;
        border: 0;
        background: transparent;
        outline: none;
        cursor: pointer;

        &.btnPrev {
            margin-right: 8px;
            &:disabled {
                opacity: 0.5;
            }
        }

        &.btnNext {
            margin-left: 8px;
            &:disabled {
                opacity: 0.5;
            }
        }

        &.active {
            background: var(--blue-main-color);
            color: #fff;
        }
    }
}
