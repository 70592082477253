.wrapperDeleteCheckDialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        background: rgba(31, 41, 55, 0.32);
        z-index: 1;
        cursor: pointer;
    }

    .dialog {
        position: relative;
        max-width: 464px;
        width: 100%;
        background: var(--main-background-color);
        border-radius: 12px;
        border: 1px solid #E5E7EB;
        overflow: hidden;
        z-index: 2;

        .btnClose {
            position: absolute;
            right: 12px;
            top: 12px;
            background: transparent;
            border: 0;
            padding: 0;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .header {
            padding: 32px 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.isProgress {
                svg path {
                    stroke: var(--blue-main-color);
                }

                .title {
                    color: var(--blue-main-color);
                }
            }

            &.done {
                .title {
                    color: #22C55E;
                }
            }

            .title {
                margin-top: 16px;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                color: var(--red-main-color);
            }
        }

        .body {
            padding: 32px 70px;
            background: #F9FAFB;

            &.isProgress {
                padding: 32px;
            }

            .actions {
                margin-top: 30px;
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;

                strong {
                    color: #6B7280;
                    font-weight: 500;
                }
            }

            .progressInfo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    margin-right: 8px;
                    color: #6B7280;
                }

                strong {
                    width: 100%;
                    font-weight: 500;
                    &:last-child {
                        text-align: right;
                        width: 80px;
                    }
                }
            }

            .progressBar {
                margin: 16px 0;
                height: 24px;
                border-radius: 6px;
                background: var(--gray-light-color);
                padding: 2px;
                box-shadow: 0px 1px 4px 0px #E9EAEC inset;

                .line {
                    height: 100%;
                    border-radius: 3px;
                    width: 10%;
                    background: var(--blue-main-color);
                    transition: all 0.3s;
                }
            }

            .wrapperItems {
                height: 240px;
                border-radius: 6px;
                border: 1px solid var(--border-color);
                background: var(--main-background-color);
                overflow: scroll;

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    padding: 0 12px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    border-bottom: 1px solid var(--border-color);
                    &:last-child {
                        border: 0;
                    }

                    span {
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    svg {
                        flex-shrink: 0;
                        margin-left: 8px;
                    }
                }

                .headerItem {
                    position: sticky;
                    top: 0;
                    background: #F9FAFB;
                    padding: 12px;
                    color: #4B5563;
                    text-align: left;
                }
            }
        }
    }
}
