.deletePaymentInfo {
    padding-top: 32px;
    display: flex;
    align-items: center;
    gap: 12px;

    .item {
        width: 20%;
        border: 1px solid var(--border-color);
        border-radius: 12px;
        padding: 24px;
        flex-grow: 1;

        .title {
            color: var(--Gray-500, #6B7280);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 16px;
        }

        .value {
            color: var(--Gray-800, #1F2937);
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }
}
