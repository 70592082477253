.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    background: var(--header-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9CA3AF;
}
